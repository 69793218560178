// $hsny-orange: #d55c19;//R 213 G 92 B 25//colors as per NOEP-675
// $hsny-green: #5a8f22;//R 91 G 143 B 34
// $hsny-purple: #522398;//R 82 G 35 B 152
// $hsny-grey: #808285;//R 128 G 130 B 133

$hsny-orange: #ea6820;//colors from the logo image
$hsny-green: #519136;
$hsny-purple: #4e3594;
$hsny-grey: #9fa0a2;

$hsny-orange-darker: #c9591a;
$hsny-green-darker: #396926;
$hsny-purple-darker: #3d2975;
$hsny-grey-darker: #87888a;

$hsny-green-lighter: #96bf84;

$hsny-disabled: #c7c7c7;
$hsny-disabled-darker: #bababa;
