@use '@angular/material' as mat;
@import "./style-variables.scss";
@import "primeicons/primeicons.css";

.sticky-footer-container {
    position: absolute; top: 0; min-height: 100%; width:100%;
}
.sticky-footer-content {
    padding-bottom: 4rem;
}
.sticky-footer-footer {
    position: absolute; bottom: 0; height: 4rem; margin-top: -4rem;
}
.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.hsny-orange {
    color: $hsny-orange;
}

.hsny-green {
    color: $hsny-green;
}

.hsny-purple {
    color: $hsny-purple;
}

.hsny-grey {
    color: $hsny-grey;
}

.hsny-bg-orange {
    background-color: $hsny-orange;
}

.hsny-bg-green {
    background-color: $hsny-green;
}

.hsny-bg-purple {
    background-color: $hsny-purple;
}

.hsny-bg-grey {
    background-color: $hsny-grey;
}

.value-grey-background {
    display: inline-block;
    background-color: lightgrey;
    min-width: 6em;
    min-height: 1.5em;
}

.validation-error {
    background-color: pink;
    border-radius: .5em;
    padding-left: .5em;
    padding-right: .5em;
    display: inline-block;
}

.overall-validation-error {
    background-color: pink;
    border-radius: .5em .5em .5em .5em;
    padding-left: .5em;
    padding-right: .5em;
    display: inline-block;
}

a {
    color: $hsny-purple;
}

a:hover {
    color: $hsny-purple-darker;
}

select {
    height: 2em;
}

input[type=checkbox] {
    height: 1.5em;
    width: 1.5em;
}

input[type=radio] {
    height: 1.5em;
    width: 1.5em;
    vertical-align: text-bottom;
}

.link-rebrand {
    cursor: pointer;
    font-weight: bold;
    color: $hsny-purple;
}

.link-rebrand:hover {
    color: $hsny-purple-darker;
    text-decoration: underline;
}

.link {
    cursor: pointer;
    color: $hsny-purple;
}

.link:hover {
    color: $hsny-purple-darker;
    text-decoration: underline;
}

.sort-column-header {
    white-space: nowrap;
    cursor: pointer;
    font-weight: bold;
    color: $hsny-purple;
}

.sort-column-header:hover {
    color: $hsny-purple-darker;
}

.noep-container-small {
    width: 400px !important;
    border-radius: .5em;
}

.noep-container-medium {
    width: 500px !important;
    border-radius: .5em;
}

.input-style {
    /*height: 2.2em;*/
    width: 100%;
    border-radius: .3em;
    padding-left: .5em;
    border-width: 1px;
}

.appinput {
    border-style: inset;
    // min-width: 14em;
    border-radius: .3em;
    padding-left: .5em;
    border-width: 1px;
    border-color: lightgrey;
}

.labeled-input {
    width: 10em;
    border-radius: .3em;
    padding-left: .5em;
    border-width: 1px;
}

.avatar-letter-center {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.flag {
    color: $hsny-green;
    cursor: pointer;
}

.flag:hover {
    color: $hsny-green-darker;
}

.has-comment-flag {
    color: orange;
}

.alert-icon {
    color: red;
    cursor: pointer;
}

.alert-icon:hover {
    color: darkred;
}

.comment-button {
    display: inline-block;
    font-size: .6em;
    color: lightgrey;
    box-shadow: 3px 3px 2px lightgrey;
    min-width: 3em;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    margin-left: .5em;
    margin-top: 1px;
}

.comment-button:hover {
    color: darkgrey;
}

.comment-text {
    font-style: italic;
    border-radius: .15em;

    margin-left: .3em;
    padding-left: .15em;
    padding-right: .3em;
    color: black;
    background-color: lightyellow;
    border-style: solid;
    border-width: 1px;
    border-color: $hsny-green;
}

.right-aligned-money {
    min-width: 4em;
    text-align: right;
    display: inline-block;
    padding-right: .25em;
    // border-width: 0px;
    // border-right-width: 2px;
    // border-color: white;
    // border-style: solid;
}

.right-aligned-money-header {
    min-width: 4em;
    text-align: right;
    padding-right: .25em;
    // border-width: 0px;
    // border-right-width: 2px;
    // border-color: white;
    // border-style: solid;
}

.letter-back {
    background-color: $hsny-orange;
    padding-left: .5em;
    padding-right: .5em;
    display: inline-block;
    //font-size: 1.5em;
    border-radius: 1em;
    width: 1.75em;
    height: 1.75em;
}

.letter {
    color: white;
    font-size: 1.75em;
    position: absolute;
    top: -.25em;
    left: 0;
    width: 1em;
    height: 1em;
    vertical-align: top;
}

.letter-container:hover {
    color: darkgrey;
}

.letter-container:hover {
    color: black;
}

.odd-row {
    background-color: #ececec;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// body {
//     margin: 0;
//     font-family: Roboto, "Helvetica Neue", sans-serif;
// }

// .custom-dialog-container .mat-dialog-container {
//     padding: 0;
//     width: 450px;
//     //height: 400px;
//     height: 24em;
// }

//.custom-dialog-container-no-reason .mat-dialog-container {
    //padding: 0;
    //width: 450px;
    //height: 400px;
    //height: 16em;
//}

// .add-account-dialog-container {
//     //width: 75%;
//     //height: 100%;
//     // margin-top: 15%;
// }

//.add-account-dialog-container .mat-dialog-container {
    //padding: 0;
    // width: 100%;
    // max-height: 70%;
//}

.mat-dialog-content {
    padding: 0;
}

.inline-block {
    display: inline-block;
}

.push-y-1{
    margin-top: 5px;
}
.push-y-2{
    margin-top: 10px;
}
.push-y-3{
    margin-top: 15px;
}
.push-y-4{
    margin-top: 20px;
}

.page-title{
    font-size: 1.6rem;
    background-color: lightgrey;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-title-no-font{
    background-color: lightgrey;
    padding-top: 5px;
    padding-bottom: 5px;
}

.page-title-font {
    font-size: 1.6rem;
    font-weight: 500;
}

h2.table-title{
    font-size: 1.5rem;
    color: #333333;
}

.status_badge {
    border-radius: .25em;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
}

.submission_status_APPROVED {
    background-color: #22b14c;
}
.submission_status_APPROVED_AMND {
    background-color: #396926;
}

.submission_status_DRAFT {
    background-color: #bfbfbf;
}
.submission_status_MORE_INFO { 
    background-color: #ed1c24;
}
.submission_status_NEW {
    background-color: #0072bc;
}
.submission_status_PENDING_APPROVAL {
    background-color: #ffc000;
}
.submission_status_PENDING_AUDIT {
    background-color: #c55a11;
}
.submission_status_SUBMITTED {
    background-color: #6f3198;
}
.submission_status_UNPUBLISHED {
    background-color: #a29d96
}

.step_status_D {
    background-color: #22b14c;
}
.step_status_F {
    background-color: #ed1c24;
}
.step_status_I {
    background-color: #6f3198;
}
.step_status_T {
    background-color: #0072bc;
}

$my-primary-palette: mat.define-palette(mat.$deep-purple-palette, 900);
$my-accent-palette: mat.define-palette(mat.$orange-palette);
$my-warn-palette: mat.define-palette(mat.$red-palette);

$my-theme: (
  color: (
    primary: $my-primary-palette,
    accent: $my-accent-palette,
    warn: $my-warn-palette
  ),
  typography: mat.define-typography-config(),
  density: 0,
);

$my-light-theme: mat.define-light-theme($my-theme);

@include mat.all-legacy-component-themes($my-light-theme);
@include mat.all-component-themes($my-light-theme);
